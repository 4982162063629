import React from 'react';
import dayjs from 'dayjs';
import { BigNumber } from '@ethersproject/bignumber';
import { parseUnits } from '@ethersproject/units';

import { Button } from '@src/components';
import { useStakingMethods } from '@src/components/Staking/hooks';
import {
    useAppSelector,
    useDecubateContractByAddress,
    useTokenApproval,
    useTokenBalance,
} from '@src/hooks';
import { useWeb3Onboard } from '@src/hooks/useWeb3Onboard';
import { removeCommas } from '@src/utils/web3';
import { contract_types } from '@src/constants';
import { LiquidityStakingPool, PoolToken } from '@src/ts/interfaces';

export const LiquidityDeposit: React.FC = () => {
    const { account } = useWeb3Onboard();

    const {
        current_pool,
        pools,
        deposit_amount = '0',
        second_deposit_amount = '0',
    } = useAppSelector((state) => state.staking);

    const {
        input_token,
        second_input_token,
        type,
        end_date,
        is_native: [is_native, pos],
        address,
    } = pools.find(({ id }) => id === current_pool) as LiquidityStakingPool;

    // eslint-disable-next-line
    const [_, staking_addr] = useDecubateContractByAddress(
        address,
        contract_types[type],
        true,
    );

    const cant_deposit = Number(removeCommas(deposit_amount)) === 0;

    const parsed_deposit = parseUnits(
        removeCommas(deposit_amount),
        input_token.decimals,
    );
    const parsed_deposit_token1 = parseUnits(
        removeCommas(second_deposit_amount),
        second_input_token.decimals,
    );

    const { handleDeposit, loading } = useStakingMethods();

    const {
        approved: token0_approved,
        handleApprove: handleToken0Approve,
        loading: approve_token0_loading,
    } = useTokenApproval(
        input_token.address,
        account,
        staking_addr,
        parsed_deposit.toString(),
    );

    const {
        approved: token1_approved,
        handleApprove: handleToken1Approve,
        loading: approve_token1_loading,
    } = useTokenApproval(
        second_input_token.address,
        account,
        staking_addr,
        parsed_deposit.toString(),
    );

    const token0_balance = useTokenBalance(
        input_token.address,
        is_native && pos === 0,
    );
    const token1_balance = useTokenBalance(
        second_input_token.address,
        is_native && pos === 1,
    );

    const pool_is_expired = dayjs().isAfter(dayjs(end_date || new Date()));
    const more_than_available =
        BigNumber.from(parsed_deposit).gt(token0_balance) ||
        BigNumber.from(parsed_deposit_token1).gt(token1_balance);

    const deposit_disabled =
        pool_is_expired || cant_deposit || more_than_available;

    const enable_button_data = [
        [
            token0_approved,
            input_token,
            approve_token0_loading,
            handleToken0Approve,
        ],
        [
            token1_approved,
            second_input_token,
            approve_token1_loading,
            handleToken1Approve,
        ],
    ].filter((_, idx) => !is_native || (is_native && pos !== idx)) as [
        boolean,
        PoolToken,
        boolean,
        () => Promise<void>,
    ][];

    return (
        <div className="flex space-x-2">
            {enable_button_data.filter(([approved]) => approved).length ===
            enable_button_data.length ? (
                <Button
                    className="flex-1"
                    disabled={loading || deposit_disabled}
                    onClick={handleDeposit}
                    loading={loading}
                    id={'current-version'}
                >
                    Deposit
                </Button>
            ) : (
                enable_button_data.map(
                    ([approved, token, loading, handleApprove], idx) => (
                        <Button
                            key={`${token.symbol}-${idx}`}
                            className="flex-1"
                            loading={loading}
                            disabled={loading || approved}
                            onClick={handleApprove}
                        >
                            {enable_button_data.length > 1 && `${idx + 1}. `}
                            Enable {token.symbol}
                        </Button>
                    ),
                )
            )}
        </div>
    );
};
