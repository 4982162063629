import { useState } from 'react';

import { useWeb3Onboard } from '@src/hooks/useWeb3Onboard';
import { useAppDispatch, useAppSelector } from '@src/hooks';
import {
    setModalState,
    updatePool,
    setDepositAmount,
    setSecondDepositAmount,
} from '@src/features/staking';
import { contract_types } from '@src/constants';
import { executeTx } from '@src/utils/web3';
import { ContractType } from '@src/ts/constants';
import { CompoundStakingPool, StakingType } from '@src/ts/interfaces';
import { useDecubateContractByAddress } from '@src/hooks/useContract';

import { getStakeArgs, getUnstakeArgs } from './utils';
import { toast } from 'react-toastify';
import { BigNumber } from '@ethersproject/bignumber';

export const useStakingMethods = (): {
    loading: boolean;
    handleDeposit: () => Promise<void>;
    handleWithdraw: () => Promise<void>;
} => {
    const [loading, setLoading] = useState(false);
    const { account } = useWeb3Onboard();

    const {
        current_pool,
        pools,
        deposit_amount,
        second_deposit_amount,
        selected_ids,
        withdraw_percent,
    } = useAppSelector((state) => state.staking);

    const pool = pools.find(({ id }) => id === current_pool);

    const { address, type } = pool;
    const is_compound = type === StakingType.Compound;

    // contract instances
    const [staking] = useDecubateContractByAddress(
        address,
        contract_types[pool.type],
        true,
    );

    const [compounder] = useDecubateContractByAddress(
        (pool as CompoundStakingPool).vault,
        ContractType.Vault,
        true,
    );

    const contract = is_compound ? compounder : staking;

    const dispatch = useAppDispatch();

    const handleDeposit = async () => {
        setLoading(true);
        const [stake_method, stake_args] = getStakeArgs(
            pool,
            deposit_amount,
            second_deposit_amount,
            selected_ids,
        );

        const params = stake_args.slice(0, stake_args.length - 1);
        const opts = stake_args[stake_args.length - 1] as Record<
            string,
            unknown
        >;

        try {
            let gasLimit = BigNumber.from(5000000);

            gasLimit = await contract.estimateGas[stake_method](...params, {
                ...opts,
                gasLimit,
            });
            await executeTx(
                contract[stake_method](...params, {
                    ...opts,
                    gasLimit: gasLimit.mul(12).div(10),
                }),
                'Transaction successfully complete',
            );

            dispatch(updatePool(pool?.id, account));
            dispatch(setDepositAmount('0'));
            dispatch(setSecondDepositAmount('0'));
        } catch (err) {
            toast.error(err.reason || err.message);
        }

        setLoading(false);
    };

    const handleWithdraw = async () => {
        setLoading(true);

        const [unstake_method, unstake_args] = getUnstakeArgs(
            pool,
            selected_ids,
            Number(withdraw_percent),
        );
        try {
            let gasLimit = BigNumber.from(5000000);
            gasLimit = await contract.estimateGas[unstake_method](
                ...unstake_args,
                {
                    gasLimit,
                },
            );
            await executeTx(
                contract[unstake_method](...unstake_args, {
                    gasLimit: gasLimit.mul(12).div(10),
                }),
                'Transaction successfully complete',
            );
            dispatch(updatePool(pool?.id, account));
            dispatch(setModalState('Deposit'));
        } catch (err) {
            toast.error(err.reason || err.message);
        }
        setLoading(false);
    };

    return { handleDeposit, handleWithdraw, loading };
};
