import dayjs from 'dayjs';
import { BigNumber } from '@ethersproject/bignumber';
import { parseUnits } from '@ethersproject/units';

import { InfoText, Warning } from '@src/components';
import { NFTTooltip } from '@src/components/Staking/NFTTooltip';
import { useAppSelector } from '@src/hooks';
import {
    LiquidityStakingPool,
    NFTStakingPool,
    StakingType,
} from '@src/ts/interfaces';
import { effectiveApy } from '@src/utils/staking';
import { parseBalance, removeCommas } from '@src/utils/web3';
import { getPoolMultiplier } from '../utils';

export const TextItem: React.FC<{
    text: string;
    title: string;
    info?: string;
    checked?: boolean;
}> = ({ text, title, info, checked }) => {
    return (
        <div className="flex my-2">
            <div className="flex-1 text-secondary">
                {info ? <InfoText info={info} text={title} /> : title}
            </div>
            <div
                className={`flex items-center space-x-2 ${
                    checked ? 'text-success' : ''
                }`}
            >
                <strong>{text}</strong>
            </div>
        </div>
    );
};

const SummaryText: React.FC<{
    deposit_amount: string;
    withdraw_amount: BigNumber;
}> = ({ deposit_amount, withdraw_amount }) => {
    const {
        current_pool,
        pools,
        modal_state: current,
        withdraw_percent,
    } = useAppSelector((state) => state.staking);
    const pool = pools.find(({ id }) => id === current_pool);
    const {
        id,
        user_stake = '0',
        earned_reward = '0',
        has_stake,
        reward_rate = '0',
        input_token,
        reward_token,
        type,
        is_withdraw_locked,
        last_user_deposit,
        lock_period,
    } = pool || {};

    const next_balance = BigNumber.from(
        parseUnits(
            removeCommas(deposit_amount || '0'),
            input_token?.decimals || 18,
        ),
    ).add(user_stake);

    const APR =
        type === StakingType.Compound
            ? (effectiveApy(parseFloat(reward_rate) / 100, 365) * 100).toFixed(
                  2,
              ) + '%'
            : reward_rate;

    const is_compound = type === StakingType.Compound;
    const is_nft = type === StakingType.NFT;
    const is_liquidity = type === StakingType.Liquidity;

    const stake_complete = dayjs().isAfter(
        dayjs(last_user_deposit).add(parseInt(lock_period), 'days'),
    );

    const percent_to_use = is_liquidity ? withdraw_percent : 100;

    const { boost, has_boost } = getPoolMultiplier(pool);

    return (
        <>
            {current === 'Deposit' && (
                <div className="my-8">
                    {has_stake && is_nft && (
                        <TextItem
                            title="Your stake"
                            text={`${user_stake} / ${
                                (pool as NFTStakingPool).max_per_user
                            }`}
                        />
                    )}
                    <TextItem
                        title={`Lock period`}
                        text={lock_period + ' days'}
                    />
                    <TextItem
                        title={is_nft ? 'Daily reward per NFT' : `Reward`}
                        text={
                            is_nft
                                ? `${parseBalance(reward_rate)} ${
                                      pool.reward_token.symbol
                                  }`
                                : APR
                        }
                    />

                    {!is_nft && (
                        <div className="flex my-2">
                            <div className="flex-1 text-secondary">
                                <div className="flex items-center flex-wrap space-x-2">
                                    <p>NFT boost</p>
                                    <NFTTooltip id={id}>
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            className="h-6 w-6 text-accent"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            stroke="currentColor"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth={2}
                                                d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                                            />
                                        </svg>
                                    </NFTTooltip>
                                </div>
                            </div>
                            <div
                                className={`flex items-center space-x-2 ${
                                    has_boost ? 'text-success' : ''
                                }`}
                            >
                                <strong>+{has_boost ? boost : 0}%</strong>
                            </div>
                        </div>
                    )}

                    {has_stake && !is_nft && (
                        <TextItem
                            title="Stake after deposit"
                            text={`${parseBalance(
                                next_balance.add(
                                    !is_compound ? 0 : earned_reward,
                                ),
                                input_token?.decimals,
                            )} ${input_token?.symbol}`}
                        />
                    )}

                    {lock_period !== '' && (
                        <TextItem
                            title={(has_stake ? 'New l' : 'L') + 'ock end date'}
                            text={dayjs()
                                .add(Number(lock_period), 'days')
                                .format('HH:mm MMM DD, YYYY')}
                            info="You can withdraw the deposited amount and rewards after the lock date has expired."
                        />
                    )}
                </div>
            )}
            {current === 'Withdraw' && (
                <div className="my-4">
                    {!is_nft && (
                        <div className="flex my-2">
                            <div className="flex-1">
                                <p className="text-secondary">
                                    Withdraw amount
                                </p>
                            </div>
                            <div className="text-right">
                                <p>
                                    <strong>
                                        {parseBalance(
                                            withdraw_amount
                                                .mul(percent_to_use)
                                                .div(100),
                                            input_token?.decimals,
                                        )}{' '}
                                        {input_token?.symbol}
                                    </strong>
                                </p>
                                {is_liquidity && (
                                    <p>
                                        <strong>
                                            {parseBalance(
                                                BigNumber.from(
                                                    (
                                                        pool as LiquidityStakingPool
                                                    ).second_user_stake,
                                                )
                                                    .mul(percent_to_use)
                                                    .div(100),
                                                (pool as LiquidityStakingPool)
                                                    ?.second_input_token
                                                    .decimals,
                                            )}{' '}
                                            {
                                                (pool as LiquidityStakingPool)
                                                    ?.second_input_token.symbol
                                            }
                                        </strong>
                                    </p>
                                )}
                            </div>
                        </div>
                    )}
                    {
                        <div className="flex my-2">
                            <div className="flex-1">
                                <p className="text-secondary">Earned reward</p>
                            </div>
                            <div>
                                <p>
                                    <strong>
                                        {parseBalance(
                                            BigNumber.from(earned_reward)
                                                .mul(percent_to_use)
                                                .div(100),
                                            reward_token?.decimals,
                                        )}{' '}
                                        {reward_token?.symbol}
                                    </strong>
                                </p>
                            </div>
                        </div>
                    }
                    {!is_withdraw_locked && !stake_complete && (
                        <Warning>
                            Withdrawing before the lock end date will yield no
                            rewards.
                        </Warning>
                    )}
                </div>
            )}
        </>
    );
};

export default SummaryText;
