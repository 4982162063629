import React, { useMemo, useState } from 'react';

import { Check } from '@src/components/Check';
import { setSelectedIds } from '@src/features/staking';
import { useAppDispatch, useAppSelector } from '@src/hooks/index';
import { NFTData, NFTStakingPool } from '@src/ts/interfaces';

import { NoNFTS } from './NoNFTs';

const InputItem: React.FC<{
    nft: NFTData;
    selected_ids: number[];
    max_per_user: number;
    num_staked: number;
    available: boolean;
}> = ({
    nft: { id, data },
    selected_ids,
    max_per_user,
    num_staked,
    available,
}) => {
    const [loading, setLoading] = useState(true);
    const is_selected = useMemo(
        () => selected_ids.includes(id),
        [id, selected_ids],
    );
    const dispatch = useAppDispatch();

    const setSelected = () => {
        if (selected_ids.includes(id)) {
            dispatch(setSelectedIds(selected_ids.filter((_id) => _id !== id)));
            return;
        }

        if (available && num_staked + selected_ids.length >= max_per_user)
            return;

        dispatch(setSelectedIds([...selected_ids, id]));
    };

    return (
        <div className="text-left" id="NFT selector">
            <div
                className={`relative rounded cursor-pointer ${
                    is_selected ? 'border border-accent' : ''
                }`}
                onClick={setSelected}
            >
                <img
                    src={data}
                    alt={id.toString()}
                    className={`rounded w-full bg-main aspect-square ${
                        loading ? 'animate-pulse' : ''
                    }`}
                    onLoad={() => setLoading(false)}
                />
                {is_selected && (
                    <div className="absolute top-2 left-2 border border-custom rounded">
                        <Check className="text-accent" />
                    </div>
                )}
            </div>

            <p className="text-secondary">#{id}</p>
        </div>
    );
};

export const NFTSelect: React.FC<{
    pool: NFTStakingPool;
    available?: boolean;
}> = ({ pool, available = true }) => {
    const { selected_ids } = useAppSelector((state) => state.staking);
    const tokens_to_use = available ? 'available_tokens' : 'staked_tokens';
    const nfts = pool[tokens_to_use];
    return nfts.length > 0 ? (
        <div className="grid grid-cols-2 gap-2 max-h-72 overflow-scroll">
            {nfts.map((nft) => (
                <InputItem
                    key={`nft-input-${nft.id}`}
                    nft={nft}
                    selected_ids={selected_ids}
                    max_per_user={pool.max_per_user}
                    num_staked={pool.staked_tokens.length}
                    available={available}
                />
            ))}
        </div>
    ) : (
        <NoNFTS
            text="You are staking all of your available NFTs"
            url={pool.collection_url}
        />
    );
};
