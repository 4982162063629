import { useAppSelector } from '@src/hooks';

import { Button } from '@src/components';
import { useWeb3Onboard } from '@src/hooks/useWeb3Onboard';
import { Deposit } from './Deposit';
import { Withdraw } from './Withdraw';
import { Bounty } from './Bounty';

type ModalState = 'Deposit' | 'Bounty' | 'Withdraw';

const render = (state: ModalState) => {
    switch (state) {
        case 'Bounty':
            return <Bounty />;
        case 'Withdraw':
            return <Withdraw />;
        default:
            return <Deposit />;
    }
};

export const Footer: React.FC = () => {
    const { connect, account } = useWeb3Onboard();

    const { modal_state } = useAppSelector((state) => state.staking);

    return (
        <div className="w-full bg-level-two p-4 absolute bottom-0">
            {account ? (
                render(modal_state as ModalState)
            ) : (
                <Button className="w-full" onClick={() => connect(undefined)}>
                    Connect wallet
                </Button>
            )}
        </div>
    );
};
