import { Button } from '@src/components';
import { useWeb3Onboard } from '@src/hooks/useWeb3Onboard';
import Link from 'next/link';

export const NoNFTS: React.FC<{
    text: string;
    className?: string;
    url: string;
}> = ({ url = '', text, className = '' }) => {
    const { connect } = useWeb3Onboard();

    return (
        <div
            className={`w-full flex items-center justify-center text-center p-5 ${className}`}
        >
            <div>
                <img
                    src="/icons/no-nfts.svg"
                    className="mx-auto my-8"
                    alt="Staked all NFTs"
                />
                <h3 className="text-lg text-primary my-8">
                    <strong>{text}</strong>
                </h3>
                <Link href={url}>
                    <a target="_blank" rel="noopener,noreferrer">
                        <Button className="w-full">Get NFTs</Button>
                    </a>
                </Link>

                <p
                    className="my-8 text-secondary underline cursor-pointer"
                    onClick={() => connect(undefined)}
                >
                    Change wallet
                </p>
            </div>
        </div>
    );
};
