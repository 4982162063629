import { parseUnits, commify } from '@ethersproject/units';

import { CurrencyInput } from '@src/components/Input';
import {
    setDepositAmount,
    setSecondDepositAmount,
} from '@src/features/staking';
import { useAppDispatch, useAppSelector, useTokenBalance } from '@src/hooks';
import {
    parseBalance,
    removeCommas,
    evaluateWeiBalance,
} from '@src/utils/web3';
import {
    LiquidityStakingPool,
    Reserves,
    StakingPool,
    StakingType,
} from '@src/ts/interfaces';
import { ChangeEvent } from '@src/ts/types';
import { DEFAULT_TOKEN } from '@src/config';
import { BigNumber } from '@ethersproject/bignumber';

const { symbol: default_symbol, decimals: default_decimals } = DEFAULT_TOKEN;

export const NumberInput: React.FC<{
    pool: StakingPool;
    reserves: Reserves;
}> = ({ pool, reserves }) => {
    const { loading, deposit_amount } = useAppSelector(
        (state) => state.staking,
    );
    const dispatch = useAppDispatch();

    const { input_token, is_native = [false, 0] } =
        pool as LiquidityStakingPool;

    const balance = useTokenBalance(
        input_token.address,
        is_native[0] && is_native[1] === 0,
    );

    const { hardcap, total_staked } = pool;
    const { symbol = default_symbol, decimals = default_decimals } =
        pool?.input_token || {};

    const setSecondDeposit = (amount: string) => {
        const amount_token1 = parseUnits(
            removeCommas(amount),
            input_token.decimals,
        )
            .mul(reserves.reserve1)
            .div(reserves.reserve0.eq(0) ? 1 : reserves.reserve0);

        dispatch(
            setSecondDepositAmount(
                parseBalance(
                    amount_token1,
                    (pool as LiquidityStakingPool).second_input_token
                        ?.decimals || 18,
                    18,
                ),
            ),
        );
    };

    const is_liquidity = pool.type === StakingType.Liquidity;

    const handleChange = (e: ChangeEvent) => {
        let amount = parseFloat(removeCommas(e.target.value));
        amount = isNaN(amount) ? 0 : amount;
        dispatch(setDepositAmount(commify(amount)));
        if (is_liquidity) setSecondDeposit(amount.toString());
    };

    const handleMaxClick = () => {
        const remaining = BigNumber.from(hardcap).sub(total_staked);
        const min = is_liquidity
            ? balance
            : BigNumber.from(balance).gt(remaining)
            ? remaining
            : balance;

        const parsed_val = parseUnits(
            removeCommas(parseBalance(min, input_token.decimals)),
            input_token.decimals,
        ).toString();

        let amount = evaluateWeiBalance(
            parsed_val,
            min.toString(),
            input_token.decimals,
        );

        amount = Number(amount) < 0 ? '0' : amount;

        dispatch(
            // eval wei balance will reduce amount if rounded up
            setDepositAmount(amount),
        );
        if (is_liquidity) setSecondDeposit(amount);
    };
    const deposit = parseUnits(
        removeCommas(deposit_amount || '0'),
        input_token.decimals,
    );

    const more_than_available = deposit.gt(balance);

    return (
        <div className="text-left">
            <div className="w-full">
                <CurrencyInput
                    loading={loading}
                    valid={!more_than_available}
                    value={deposit_amount}
                    onChange={handleChange}
                    onMaxClick={handleMaxClick}
                    currency={symbol}
                />
            </div>
            <div className="flex items-center mt-4 text-sm">
                <div className="flex-1 text-sm text-secondary">
                    Available to stake
                </div>
                <strong
                    className={`${more_than_available ? 'text-primary' : ''}`}
                >
                    {`${parseBalance(balance, decimals)} ${symbol}`}
                </strong>
            </div>
        </div>
    );
};
