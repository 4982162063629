import React, { useState } from 'react';
import { BigNumber } from '@ethersproject/bignumber';

import { useStakingMethods } from '@src/components/Staking/hooks';
import {
    useAppDispatch,
    useAppSelector,
    useDecubateContractByAddress,
} from '@src/hooks';

import { Button } from '@src/components';
import { executeTx } from '@src/utils/web3';
import { useWeb3Onboard } from '@src/hooks/useWeb3Onboard';
import { contract_types } from '@src/constants';
import { updatePool } from '@src/features/staking';
import { StakingType } from '@src/ts/interfaces';

const claim_func_names = {
    [StakingType.Liquidity]: 'claim',
    [StakingType.NFT]: 'claimAndRestake',
};

export const Withdraw: React.FC = () => {
    const [left_loading, setLeftLoading] = useState(false);

    const { account } = useWeb3Onboard();
    const dispatch = useAppDispatch();
    2;
    const { current_pool, pools, selected_ids } = useAppSelector(
        (state) => state.staking,
    );

    const {
        id,
        user_stake,
        type,
        can_claim,
        contract_idx,
        address,
        is_withdraw_locked,
    } = pools.find(({ id }) => id === current_pool);

    // contract instances
    const [staking] = useDecubateContractByAddress(
        address,
        contract_types[type],
        true,
    );

    const { handleWithdraw, loading } = useStakingMethods();

    const handleClaim = async () => {
        setLeftLoading(true);
        await executeTx(
            staking[claim_func_names[type]](contract_idx),
            `Successfully claimed rewards from pool`,
            async () => {
                dispatch(updatePool(id, account));
            },
        );
        setLeftLoading(false);
    };
    const is_nft = StakingType.NFT === type;
    const is_liquidity = StakingType.Liquidity === type;
    const is_nft_and_none_selected = is_nft && selected_ids.length === 0;
    const is_normal_staking_and_no_stake =
        !is_nft && BigNumber.from(user_stake).eq(0);

    const has_claim = [StakingType.NFT, StakingType.Liquidity].includes(type);
    const cant_withdraw =
        is_normal_staking_and_no_stake ||
        is_nft_and_none_selected ||
        (type === StakingType.Liquidity && is_withdraw_locked);
    const is_claimable = can_claim || (is_liquidity && is_withdraw_locked);

    return (
        <div className="flex space-x-2">
            {has_claim && (
                <Button
                    className="flex-1"
                    loading={left_loading}
                    disabled={left_loading || !is_claimable}
                    onClick={handleClaim}
                >
                    Claim{is_nft && ' & relock'}
                </Button>
            )}
            <Button
                className="flex-1"
                disabled={loading || cant_withdraw}
                onClick={handleWithdraw}
                loading={loading}
                id="withdraw-btn"
            >
                <strong>Withdraw</strong>
            </Button>
        </div>
    );
};
