import React, { useState } from 'react';
import { Transition } from '@headlessui/react';

import { useAppDispatch, useAppSelector } from '@src/hooks';
import { setModalState, setStakingDrawerOpen } from '@src/features/staking';

import { hexToRgb } from '@src/utils/device';
import { LEVEL_ONE_COLOR } from '@src/config';

const rgb = hexToRgb(LEVEL_ONE_COLOR);

export const DrawerContainer: React.FC = ({ children }) => {
    const [inside_card, setInsideCard] = useState(false);
    const dispatch = useAppDispatch();
    const { staking_modal_open: open } = useAppSelector(
        (state) => state.staking,
    );

    return (
        <Transition
            data-testid="transition"
            show={open}
            enter="transition-opacity duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            className={`min-w-screen fixed left-0 top-0 flex justify-center items-center inset-0 z-50 outline-none focus:outline-none text-white overflow-auto`}
            style={{
                backgroundColor: `rgb(${rgb.r}, ${rgb.g}, ${rgb.b}, 0.8)`,
            }}
            onMouseDown={() => {
                if (!inside_card) {
                    dispatch(setStakingDrawerOpen(false));
                    setTimeout(() => dispatch(setModalState('Deposit')), 300);
                }
            }}
        >
            <Transition.Child
                data-testid="transition-child"
                className="relative text-primary w-full max-w-screen sm:max-w-sm relative ml-auto my-auto shadow-lg bg-dcb min-h-screen border-l-2 border-custom bg-level-one"
                enter="transition ease-in-out duration-300 transform"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
                onMouseEnter={() => setInsideCard(true)}
                onMouseLeave={() => setInsideCard(false)}
                id="staking-modal"
            >
                {children}
            </Transition.Child>
        </Transition>
    );
};
