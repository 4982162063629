import React, { useEffect, useMemo, useState } from 'react';
import { BigNumber } from '@ethersproject/bignumber';

import { useAppDispatch, useAppSelector, useTokenPrice } from '@src/hooks';

import { Footer } from './Footer';
import { Input } from './Input';
import ModalHeader from './ModalHeader';
import SummaryText from './SummaryText';
import Switch from './Switch';
import { NFTSelect } from './NFTSelect';
import {
    CompoundStakingPool,
    NFTStakingPool,
    StakingType,
} from '@src/ts/interfaces';
import { NoNFTS } from './NoNFTs';

import { DrawerContainer } from './Container';
import { Slider } from '@src/components/Slider';
import { setWithdrawPercent } from '@src/features/staking';
import { ContractType } from '@src/ts/constants';
import { DEFAULT_TOKEN } from '@src/config';
import { parseBalance } from '@src/utils/web3';
import { getContractByAddress } from '@src/contracts/index';

const isNFTAndNoNFTs = ({
    type,
    available_tokens,
    staked_tokens,
}: NFTStakingPool) => {
    return (
        type === StakingType.NFT &&
        available_tokens.length === 0 &&
        staked_tokens.length === 0
    );
};

const Bounty: React.FC = () => {
    const { current_pool, pools } = useAppSelector((state) => state.staking);
    const { vault, contract_idx } = pools.find(
        ({ id }) => id === current_pool,
    ) as CompoundStakingPool;
    const [bounty, setBounty] = useState(BigNumber.from(0));
    const [initial, setInitial] = useState(true);

    const compounder_query = getContractByAddress(vault, ContractType.Vault);
    const price = useTokenPrice();

    useEffect(() => {
        if (initial) {
            setInitial(false);
            compounder_query
                .calculateHarvestDcbRewards(contract_idx)
                .then((n: BigNumber) => setBounty(n));
        }

        const handler = setInterval(
            () =>
                compounder_query
                    .calculateHarvestDcbRewards(contract_idx)
                    .then((n: BigNumber) => setBounty(n)),
            5000,
        );

        return () => {
            clearInterval(handler);
        };
    }, [compounder_query, contract_idx]);

    const usd_bounty = bounty
        .mul(price)
        .div(BigInt(10 ** DEFAULT_TOKEN.decimals).toString());

    return (
        <div className="pt-4 px-2">
            <div className="px-4 py-6 text-center bg-level-three rounded border border-custom">
                <h3
                    className="text-primary text-2xl"
                    data-testid="bounty-value"
                >
                    {parseBalance(bounty, DEFAULT_TOKEN.decimals, 6)}{' '}
                    {DEFAULT_TOKEN.symbol}
                </h3>

                <p className="text-secondary">
                    ~ {parseBalance(usd_bounty, 18, 6)} USD
                </p>
            </div>
            <p className="text-left text-sm text-secondary mt-4">
                Claim bounty straight to your wallet and compound staking
                rewards for everyone in this pool.
            </p>
        </div>
    );
};

const StakingDrawer: React.FC = () => {
    const deposit_amount =
        useAppSelector((state) => state?.staking?.deposit_amount) || '0';
    const {
        current_pool,
        pools,
        modal_state: current,
        withdraw_percent,
    } = useAppSelector((state) => state.staking);
    const dispatch = useAppDispatch();
    const pool = pools.find(({ id }) => id === current_pool);

    const withdraw_amount = BigNumber.from(pool?.user_stake || 0);

    const is_liquidity = pool?.type === StakingType.Liquidity;
    const is_nft = pool?.type === StakingType.NFT;
    const is_nft_and_no_nfts = useMemo(
        () => isNFTAndNoNFTs((pool || {}) as NFTStakingPool),
        [pool],
    );

    const onSliderChange = (v: string) => {
        dispatch(setWithdrawPercent(v));
    };

    return (
        <DrawerContainer>
            <div className="pb-12">
                <ModalHeader />
                {is_nft_and_no_nfts ? (
                    <NoNFTS
                        text="Looks like you don’t have any compatible NFTs to stake in this pool"
                        className="h-[32rem] p-10"
                        url={(pool as NFTStakingPool).collection_url}
                    />
                ) : (
                    <>
                        <div className="p-4">
                            <div className="bg-level-two p-4 text-center rounded">
                                <Switch />
                                {(current === 'Deposit' && (
                                    <Input pool={pool} />
                                )) ||
                                    (current === 'Withdraw' && is_nft && (
                                        <div className="mt-4">
                                            <NFTSelect
                                                pool={pool as NFTStakingPool}
                                                available={false}
                                            />
                                        </div>
                                    ))}
                                {current === 'Withdraw' && is_liquidity && (
                                    <Slider
                                        className="mt-4"
                                        onChange={(v) => onSliderChange(v)}
                                        max={100}
                                        min={5}
                                        step={5}
                                        value={withdraw_percent.toString()}
                                        is_percentage={true}
                                    />
                                )}
                                {current === 'Bounty' && <Bounty />}
                            </div>
                            <SummaryText
                                deposit_amount={deposit_amount}
                                withdraw_amount={withdraw_amount}
                            />
                        </div>
                        <Footer />
                    </>
                )}
            </div>
        </DrawerContainer>
    );
};

export default StakingDrawer;
