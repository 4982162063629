import React from 'react';

import { useAppSelector } from '@src/hooks';

import { StandardDeposit } from './StandardDeposit';
import { StakingType } from '@src/ts/interfaces';
import { NFTDeposit } from './NFTDeposit';
import { LiquidityDeposit } from './LiquidityDeposit';

export const Deposit: React.FC = () => {
    const { current_pool, pools } = useAppSelector((state) => state.staking);

    const { type } = pools.find(({ id }) => id === current_pool);

    switch (type) {
        case StakingType.NFT:
            return <NFTDeposit />;
        case StakingType.Liquidity:
            return <LiquidityDeposit />;
        default:
            return <StandardDeposit />;
    }
};
