import React, { useState } from 'react';
import { toast } from 'react-toastify';

import {
    useAppDispatch,
    useAppSelector,
    useDecubateContractByAddress,
} from '@src/hooks';

import { Button } from '@src/components';
import { executeTx } from '@src/utils/web3';
import { useWeb3Onboard } from '@src/hooks/useWeb3Onboard';
import { updatePool } from '@src/features/staking';
import { CompoundStakingPool } from '@src/ts/interfaces';
import { ContractType } from '@src/ts/constants';

export const Bounty: React.FC = () => {
    const [loading, setLoading] = useState(false);
    const { account } = useWeb3Onboard();
    const { current_pool, pools } = useAppSelector((state) => state.staking);
    const dispatch = useAppDispatch();

    const { contract_idx, vault, id } = pools.find(
        ({ id }) => id === current_pool,
    ) as CompoundStakingPool;

    // contract instances
    const [compounder] = useDecubateContractByAddress(
        vault,
        ContractType.Vault,
        true,
    );

    const handleClaim = async () => {
        const gasLimit = await compounder.estimateGas.harvest(contract_idx, {
            gasLimit: '5000000',
        });
        setLoading(true);

        try {
            await executeTx(
                compounder.harvest(contract_idx, {
                    gasLimit: gasLimit.mul(12).div(10),
                }),
                `Successfully claimed bounty from pool`,
                async () => {
                    dispatch(updatePool(id, account));
                },
            );
            setLoading(false);
        } catch (err) {
            toast.error(err.message);
            setLoading(false);
        }
    };

    return (
        <Button
            className="w-full"
            disabled={loading}
            onClick={handleClaim}
            loading={loading}
            id="claim-bounty-btn"
        >
            <strong>Claim bounty</strong>
        </Button>
    );
};
