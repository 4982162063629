import React from 'react';

import { useAppDispatch, useAppSelector } from '@src/hooks';
import { setModalState, setStakingDrawerOpen } from '@src/features/staking';
import { Icon } from '@src/components/Staking/Icon';
import { NFTStakingPool, StakingType } from '@src/ts/interfaces';
import { Cross } from '@src/components/Icon';

const ModalHeader: React.FC = () => {
    const dispatch = useAppDispatch();

    const { current_pool, pools } = useAppSelector((state) => state.staking);
    const pool = pools.find(({ id }) => id === current_pool);

    const is_nft = pool.type === StakingType.NFT;

    return (
        <div
            className={`h-40 relative overflow-hidden ${
                is_nft ? '' : 'bg-staking-header'
            }`}
        >
            <button
                data-testid="modal-header-btn"
                className="absolute top-4 left-4 z-50"
                onClick={() => {
                    dispatch(setModalState('Deposit'));
                    dispatch(setStakingDrawerOpen(false));
                }}
            >
                <Cross />
            </button>

            {!is_nft && (
                <Icon pool={pool} className="absolute left-4 bottom-4" />
            )}
            {is_nft && (
                <h3 className="absolute left-4 bottom-4 text-2xl z-50">
                    <strong>{pool.name}</strong>
                </h3>
            )}
            {is_nft && (
                <img
                    alt={pool.name}
                    src={(pool as NFTStakingPool).header_logo}
                    className="w-full absolute"
                />
            )}
        </div>
    );
};

export default ModalHeader;
