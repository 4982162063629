import { parseUnits, commify } from '@ethersproject/units';

import { CurrencyInput } from '@src/components/Input';
import {
    setDepositAmount,
    setSecondDepositAmount,
} from '@src/features/staking';
import { useAppDispatch, useAppSelector, useTokenBalance } from '@src/hooks';
import {
    parseBalance,
    removeCommas,
    evaluateWeiBalance,
} from '@src/utils/web3';
import { LiquidityStakingPool, Reserves } from '@src/ts/interfaces';
import { ChangeEvent } from '@src/ts/types';
import { DEFAULT_TOKEN } from '@src/config';

const { symbol: default_symbol, decimals: default_decimals } = DEFAULT_TOKEN;

export const SecondNumberInput: React.FC<{
    pool: LiquidityStakingPool;
    reserves: Reserves;
}> = ({ pool, reserves }) => {
    const { loading, second_deposit_amount } = useAppSelector(
        (state) => state.staking,
    );
    const dispatch = useAppDispatch();
    const balance = useTokenBalance(
        pool.second_input_token.address,
        pool.is_native[0] && pool.is_native[1] === 1,
    );

    const { symbol = default_symbol, decimals = default_decimals } =
        pool?.second_input_token || {};

    const setFirstDepositAmount = (amount: string) => {
        const val = reserves.reserve1.eq(0);
        const amount_token1 = parseUnits(removeCommas(amount), decimals)
            .mul(reserves.reserve0)
            .div(val ? 1 : reserves.reserve1);

        dispatch(
            setDepositAmount(
                parseBalance(amount_token1, pool.input_token.decimals, 6),
            ),
        );
    };

    const handleChange = (e: ChangeEvent) => {
        let amount = parseFloat(removeCommas(e.target.value));
        amount = isNaN(amount) ? 0 : amount;
        dispatch(setSecondDepositAmount(commify(amount)));
        setFirstDepositAmount(amount.toString());
    };

    const handleMaxClick = () => {
        const parsed_balance = parseUnits(
            removeCommas(parseBalance(balance, decimals)),
            decimals,
        ).toString();
        const amount = evaluateWeiBalance(parsed_balance, balance);
        dispatch(
            // eval wei balance will reduce amount if rounded up
            setSecondDepositAmount(amount),
        );
        setFirstDepositAmount(amount);
    };
    const deposit = parseUnits(
        removeCommas(second_deposit_amount || '0'),
        decimals,
    );

    const more_than_available = deposit.gt(balance);

    return (
        <div className="text-left mt-4">
            <div className="w-full">
                <CurrencyInput
                    loading={loading}
                    valid={!more_than_available}
                    value={second_deposit_amount}
                    onChange={handleChange}
                    onMaxClick={handleMaxClick}
                    currency={symbol}
                />
            </div>
            <div className="flex items-center mt-4 text-sm">
                <div className="flex-1 text-sm text-secondary">
                    Available to stake
                </div>
                <strong
                    className={`${more_than_available ? 'text-primary' : ''}`}
                >
                    {`${parseBalance(balance, decimals)} ${symbol}`}
                </strong>
            </div>
        </div>
    );
};
