import { usePairReserves } from '@src/hooks/usePairReserves';
import {
    LiquidityStakingPool,
    NFTStakingPool,
    StakingPool,
    StakingType,
} from '@src/ts/interfaces';

import { NFTSelect } from '../NFTSelect';
import { NumberInput } from './NumberInput';
import { SecondNumberInput } from './SecondNumberInput';

export const Input: React.FC<{
    pool: StakingPool;
}> = ({ pool }) => {
    if (pool.type === StakingType.NFT)
        return <NFTSelect pool={pool as NFTStakingPool} />;

    const reserves = usePairReserves((pool as LiquidityStakingPool).pair);

    return (
        <>
            <NumberInput pool={pool} reserves={reserves} />
            {pool.type === StakingType.Liquidity && (
                <SecondNumberInput
                    pool={pool as LiquidityStakingPool}
                    reserves={reserves}
                />
            )}
        </>
    );
};
