import React from 'react';

import { useAppDispatch, useAppSelector } from '@src/hooks';

import { Tooltip } from '../../Tooltip';
import { setModalState } from '@src/features/staking';
import { StakingType } from '@src/ts/interfaces';

const Switch: React.FC = () => {
    const dispatch = useAppDispatch();

    const {
        current_pool,
        pools,
        modal_state: current,
    } = useAppSelector((state) => state.staking);
    const pool = pools.find(({ id }) => id === current_pool);
    const unlocked =
        pool.has_stake &&
        (pool.can_claim ||
            pool.type === StakingType.Liquidity ||
            !pool.is_withdraw_locked);
    return (
        <div
            className={`bg-level-one flex rounded cursor-pointer text-sm ${
                (current === 'Deposit' && 'mb-4') || ''
            }`}
        >
            <div
                data-testid="deposit-div"
                onClick={() => dispatch(setModalState('Deposit'))}
                className={`flex-1 px-4 py-2 rounded border-2 border-level-one ${
                    current === 'Deposit'
                        ? 'bg-level-two'
                        : 'text-secondary bg-level-one'
                }`}
            >
                {current === 'Deposit' ? <strong>Deposit</strong> : 'Deposit'}
            </div>
            {pool.type === StakingType.Compound && (
                <div
                    data-testid="bounty-div"
                    onClick={() => dispatch(setModalState('Bounty'))}
                    className={`flex-1 px-4 py-2 rounded border-2 border-level-one ${
                        current === 'Bounty'
                            ? 'bg-level-two'
                            : 'text-secondary bg-level-one'
                    }`}
                >
                    {current === 'Bounty' ? <strong>Bounty</strong> : 'Bounty'}
                </div>
            )}
            <div
                data-testid="withdraw-div"
                onClick={() => unlocked && dispatch(setModalState('Withdraw'))}
                className={`flex-1 px-4 py-2 rounded border-2 border-level-one ${
                    current === 'Withdraw'
                        ? 'bg-level-two'
                        : 'text-secondary bg-level-one'
                }`}
            >
                <Tooltip
                    text="You can withdraw the deposited amount and rewards after the lock date has expired."
                    active={!unlocked}
                >
                    <div className="flex items-center">
                        {!unlocked && (
                            <svg
                                data-testid="svg"
                                xmlns="http://www.w3.org/2000/svg"
                                className="h-5 w-5 pr-1"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                            >
                                <path
                                    fillRule="evenodd"
                                    d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z"
                                    clipRule="evenodd"
                                />
                            </svg>
                        )}
                        <div className="flex-1">
                            {current === 'Withdraw' ? (
                                <strong>Withdraw</strong>
                            ) : (
                                'Withdraw'
                            )}
                        </div>
                    </div>
                </Tooltip>
            </div>
        </div>
    );
};

export default Switch;
