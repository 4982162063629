import React from 'react';
import dayjs from 'dayjs';

import { useStakingMethods } from '@src/components/Staking/hooks';
import { Button } from '@src/components';
import { useAppSelector, useNFTApproval } from '@src/hooks';

import { useWeb3Onboard } from '@src/hooks/useWeb3Onboard';
import { NFTStakingPool } from '@src/ts/interfaces';

export const NFTDeposit: React.FC = () => {
    const { account } = useWeb3Onboard();

    const { current_pool, pools, selected_ids } = useAppSelector(
        (state) => state.staking,
    );

    const pool = pools.find(({ id }) => id === current_pool) as NFTStakingPool;
    const { input_token, end_date, address } = pool;

    const { address: input_addr } = input_token;

    const { handleDeposit, loading } = useStakingMethods();

    const {
        approved,
        handleApprove,
        loading: approve_loading,
    } = useNFTApproval(input_addr, account, address);

    const pool_is_expired = dayjs().isAfter(dayjs(end_date || new Date()));

    return (
        <div className="flex space-x-2">
            <Button
                className="flex-1"
                loading={approve_loading}
                disabled={approve_loading || pool_is_expired || approved}
                onClick={handleApprove}
                id="enable-btn"
            >
                1. Enable
            </Button>

            <Button
                className="flex-1"
                disabled={
                    loading ||
                    pool_is_expired ||
                    !approved ||
                    selected_ids.length === 0
                }
                onClick={handleDeposit}
                loading={loading}
                id="deposit-btn"
            >
                2. Deposit
            </Button>
        </div>
    );
};
