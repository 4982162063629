import React from 'react';
import { Range } from 'react-range';

import { ACCENT_COLOR, CORNER_ROUNDING, LEVEL_ONE_COLOR } from '@src/config';

interface SliderProps {
    className?: string;
    inputClassName?: string;
    label?: string;
    max: number;
    min?: number;
    step?: number;
    onChange: (e) => void;
    value: string;
    is_percentage?: boolean;
}

export const Slider: React.FC<SliderProps> = ({
    className,
    label,
    max,
    min = 0,
    step = 1,
    onChange,
    value,
    is_percentage,
}) => {
    return (
        <div className={`${className || ''}`}>
            <label
                htmlFor="default-range"
                className="block mb-2 text-sm font-medium text-secondary"
            >
                {label}
            </label>
            <div className="bg-level-two rounded-lg flex space-x-4 items-center">
                <Range
                    step={step}
                    min={min}
                    max={max}
                    values={[Number(value)]}
                    onChange={(values) => onChange(values[0])}
                    renderTrack={({ props, children }) => (
                        <div
                            {...props}
                            style={{
                                ...props.style,
                                height: '0.5rem',
                                width: '100%',
                                backgroundColor: LEVEL_ONE_COLOR,
                                borderRadius: CORNER_ROUNDING,
                            }}
                        >
                            {children}
                        </div>
                    )}
                    renderThumb={({ props }) => (
                        <div
                            {...props}
                            style={{
                                ...props.style,
                                height: '1rem',
                                width: '1rem',
                                borderRadius: CORNER_ROUNDING,
                                backgroundColor: ACCENT_COLOR,
                            }}
                        />
                    )}
                />
                {is_percentage && (
                    <div className="w-12" id="slider">
                        {value}%
                    </div>
                )}
            </div>
        </div>
    );
};
