import React from 'react';
import { BigNumber } from '@ethersproject/bignumber';
import dayjs from 'dayjs';

import { useStakingMethods } from '@src/components/Staking/hooks';
import { Button } from '@src/components';
import { useAppSelector, useTokenApproval, useTokenBalance } from '@src/hooks';
import { parseUnits } from '@ethersproject/units';
import { removeCommas } from '@src/utils/web3';
import { useWeb3Onboard } from '@src/hooks/useWeb3Onboard';
import { CompoundStakingPool, StakingType } from '@src/ts/interfaces';

export const StandardDeposit: React.FC = () => {
    const { account } = useWeb3Onboard();

    const { current_pool, pools, deposit_amount } = useAppSelector(
        (state) => state.staking,
    );

    const pool = pools.find(({ id }) => id === current_pool);
    const { input_token, type, end_date, address } = pool;

    const balance = useTokenBalance(input_token.address);

    const { decimals, address: input_addr } = input_token;

    const cant_deposit = Number(removeCommas(deposit_amount || '0')) === 0;
    const parsed_deposit = parseUnits(
        removeCommas(deposit_amount || '0'),
        decimals,
    );

    const { handleDeposit, loading } = useStakingMethods();

    const {
        approved,
        handleApprove,
        loading: approve_loading,
    } = useTokenApproval(
        input_addr,
        account,
        type === StakingType.Compound
            ? (pool as CompoundStakingPool).vault
            : address,
        parsed_deposit.toString(),
    );

    const pool_is_expired = dayjs().isAfter(dayjs(end_date || new Date()));

    const deposit = BigNumber.from(parsed_deposit);

    const more_than_available = deposit.gt(balance);

    const deposit_disabled =
        pool_is_expired || cant_deposit || more_than_available;

    return (
        <div className="flex space-x-2">
            <Button
                className="flex-1"
                loading={approve_loading}
                disabled={approve_loading || deposit_disabled || approved}
                onClick={handleApprove}
                id="enable-btn"
            >
                1. Enable
            </Button>

            <Button
                className="flex-1"
                disabled={loading || deposit_disabled || !approved}
                onClick={handleDeposit}
                loading={loading}
                id="deposit-btn"
            >
                2. Deposit
            </Button>
        </div>
    );
};
